.App {
  /*font-family: sans-serif;*/
  font-family: "Varela Round", sans-serif;
  overflow-x: hidden;
  overflow-y: hidden;

  
 
}


