.HowTo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /**background-color: rgba(59, 57, 57, 0.75);**/
    border-radius: 5px;
    min-width: 100vw;
    margin-right:50%;;
   
    margin-top: 3%;
    color: rgba(214, 195, 73, 0.8);
    font-family: 'Press Start 2P', cursive;
    font-size:auto;
   
    
 

  
  }
  ul{
    list-style: none;
  }

 

  .Terminology{
   display:flex;
   margin:auto;
 
  }
  
  
 
  
  .Info_Title {
    
    text-align: center;
    color: yellow;
    font-size:calc(12px + 1.0vw);
    
    border: 0px solid rgba(61, 61, 61, 0.9);
    box-shadow: inset 0 0 10px #000000;
    background-color: rgba(61, 61, 61, 0.4);

  }

  .Term{
    display:flex;
    flex-direction: column;
    padding:30px;
    
  }


  .MiddleText{
    font-size:calc(12px + 1.0vw);
    display:flex;
    flex-direction: column;
    font-size: 18px;
    padding: 30px;
    line-height: 30px;
    display: flex;
    justify-content: center;
    font-style: bold;
    text-align: center;
    font-family: 'Play', sans-serif;
    background-color: rgba(61, 61, 61, 0.4);
    box-shadow: inset 0 0 5px #000000;
  



  }
  .Scroll{
    box-shadow: inset 0 0 15px #000000;
   
  }
  .Deck_Container{
 
    display:flex;
    flex-direction:column;
    flex-wrap:wrap;
    
    
  }

  .Mobile{
    display:flex;
    flex-direction:column;
   
  }

  .Small_Card{
    display:flex;
    flex-wrap:wrap;
    height:0px;

    padding: 50px;
    transform:scale(0.5);
   
  }


  .VocabBtn{
   margin:auto;
   height:auto;
   padding:10px;
   border-radius: 5px;
   
  }



  
 
 
  
  
  
 