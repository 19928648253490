
.About {
 
  display: flex;
  flex-direction: column;
  justify-content: center;
  /**background-color: rgba(59, 57, 57, 0.75);**/
  border-radius: 5px;
  font-family: 'Press Start 2P', cursive;
  margin:auto;
  color: rgba(214, 195, 73);

}

.VocabBtn {
  
  color: rgba(214, 195, 73);
  background-color: rgba(61, 61, 61, 0.75);
  height: 50px;
  font-family: 'Press Start 2P', cursive;

 
  font-size:1.0em;
 
}
.Termonology {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Heading {
  text-align: center;
  padding: 20px;
  font-size:calc(12px + 1.0vw);
  color: white;
  font-family: 'Press Start 2P', cursive;
  text-shadow: 5px 1px 5px black;
  
 
  
}

.Text {

  font-size:calc(12px + 1.0vw);
  padding: 10px;
  line-height: 40px;
  display: flex;
  justify-content: center;
  font-style: bold;
  text-align: center;
  font-family: 'Play', sans-serif;
  background-color: rgba(61, 61, 61, 0.4);
  
  
  margin-left:5%;
  margin-right:5%;
  box-shadow: inset 0 0 5px #000000;
  border-radius: 5px;

  
  
  
}

.Scroll {
  background-color: black;
  overflow-y: scroll;
  overflow-x: hidden;
  height: auto;
  border-radius: 10px;
  font-family: 'Press Start 2P', cursive;

}

.Sub_Title {
  color: white;
  text-align: center;
  background-color: rgba(61, 61, 61, 0.5);
  height: 50px;
  border-radius: 5px;
  

}

li {
  padding: 5px;
}

.Scroll::-webkit-scrollbar {
  width: 1em;
  background-color: rgba(61, 61, 61, 0.5);
}

.Scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.Scroll::-webkit-scrollbar-thumb {
  background-color: rgba(95, 86, 86, 0.7);
  outline: 1px solid black;
}



