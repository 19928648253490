.Main {
  display: flex;
  flex-direction: column;
  background-color: rgba(51, 0, 51);
  font-family: 'Press Start 2P', cursive;

 
  
 
}
.Main_Title {
  display: flex;
  justify-content: center;;
 

}

.Banner {
  background-color: rgba(61, 61, 61, 0.5);

  border-radius: 1px;

  box-shadow: 0px 3px 10px black;
  

}





