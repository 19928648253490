


.Panel{
    display:flex;
    flex-direction: row;
    font-family: 'Press Start 2P', cursive;
    box-shadow: inset 0 0 5px #000000;
    

  
   
    
 
  }



  .wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    font-size:calc(12px + 1.0vw);
  }
  .one {
    grid-column: 1 / 3;
    grid-row: 1;
  }
  .two {
    grid-column: 2 / 4;
    grid-row: 1 / 3;
  }
  .three {
    grid-column: 1;
    grid-row: 2 / 5;
  }
  .four {
    grid-column: 3;
    grid-row: 3;
  }
  .five {
    grid-column: 2;
    grid-row: 4;
  }
  .six {
    grid-column: 3;
    grid-row: 4;
  }




















  
  .LeftPanel,.RightPanel{
    display:flex;
    flex-direction: column;
    margin:auto;
    padding:25px;
    

   
    

  }

  .Picture{
    margin:auto;
    border: 5px solid rgba(61, 61, 61, 0.4);
    display:flex;
    align-content: center;
    border-radius: 10px;
   


  }

  .Left_Image{
   border: 5px solid rgba(61, 61, 61, 0.4);
   margin:auto;
   border-radius: 10px;


   
  }

  .Left_Image:hover{
    transform: scale(2);
   

  }
  
  .LeftPanel{
   
 

  
  }
  
  .RightPanel{
    


}
.SubText{
  color:white;
  font-size: 24px;
  font-family: 'Press Start 2P', cursive;

  text-align: center;
  background-color: rgba(61, 61, 61, 0.75);;
}

.Download{
  color: white;
  text-decoration: none;
  font-size: 24px;
  background-color: rgba(230, 126, 34,1.0);
  width: 200px;
  border-radius:10px;
  text-align:center;
  padding: 5px;
  box-shadow: 1px 3px 1px 1px black;
  margin:auto;
  margin-bottom:15px;
  
}






